import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '@/components/Layout';
import { Container, PageContainer } from '@/components/elements';

const NotFound: React.FC<PageProps> = () => (
  <Layout>
    <main>
      <PageContainer>
        <Container>
          <h1>404</h1>
          <p>Sorry, page not found!</p>
        </Container>
      </PageContainer>
    </main>
  </Layout>
);

export default NotFound;
